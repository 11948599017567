import { HorizontalAlignedContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';
import Modal, { ModalFuncProps } from 'antd/lib/modal/Modal';
import Spin from 'antd/lib/spin';
import { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Checkbox } from 'antd';
import { TAccountNotifications } from '@app/types/accountNotifications';
import HtmlEditor from '@app/components/common/HTMLEditor/htmlEditor';

const Container = styled.form`
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

const FormTitle = styled.div`
  font-weight: 800;
  font-size: 20px;
  line-height: 23px;
  color: #272727;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0px;
  gap: 20px;
`;

const FieldLabel = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #272727;
  flex-basis: 25%;
`;

const FieldInput = styled.input<{ hasError?: boolean }>`
  flex-basis: 75%;
  background-color: transparent;
  border: ${(props) => (props.hasError ? '1px solid red' : '1px solid #acacac')};
  border-radius: 10px;
  padding: 12px;
  outline: none;
`;

const InputContainer = styled.div`
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 1rem;
  font-weight: bold;
`;

const CancelButton = styled.button`
  border: 1px solid #272727;
  border-radius: 10px;
  background-color: transparent;
  padding: 12px 70px;
  color: #272727;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

const SaveAndPublishButton = styled.button`
  border: 1px solid #3a50d1;
  border-radius: 10px;
  background-color: #3a50d1;
  padding: 12px 70px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  padding: 12px 28px;
`;

const CreateButton = styled.button`
  border: 1px solid #3a50d1;
  border-radius: 10px;
  background-color: #3a50d1;
  padding: 12px 70px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

export type TAccountNotificationsForm = {
  name: string;
  title: string;
  content: string;
  description: string;
  status: number;
  sendEmail: boolean;
};

interface INotificationFormProps extends ModalFuncProps {
  isEdit?: boolean;
  isSaveAndPublish?: boolean;
  isLoading?: boolean;
  notifications?: TAccountNotifications;
  handleMediaSelected: (files: File[], previewUrls: string[]) => void;
  currentMediaUrl: (urls: string[]) => void;
}

export default function NotificationsForm({
  isEdit,
  isLoading,
  notifications,
  handleMediaSelected,
  currentMediaUrl,
  ...modalProps
}: INotificationFormProps) {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm<Partial<TAccountNotifications>>({
    mode: 'onBlur',
  });

  useEffect(() => {
    if (modalProps.open && !!notifications) {
      reset({
        name: notifications.name,
        title: notifications.title,
        sendEmail: notifications.sendEmail,
        content: notifications.content,
      });
    } else if (modalProps.open && !notifications) {
      reset({
        name: '',
        title: '',
        sendEmail: false,
        content: '',
      });
    }
  }, [modalProps.open, notifications]);

  const canPublish = (notifications?.status as any) == 'Draft';

  const onSubmit: SubmitHandler<Partial<TAccountNotifications>> = (data) => {
    modalProps.onOk?.(data, false);
  };

  const handleSaveAndPublish: SubmitHandler<Partial<TAccountNotifications>> = (data) => {
    modalProps.onOk?.(data, true);
  };

  const handleCancel = () => {
    reset();
    modalProps.onCancel?.();
  };

  return (
    <Modal
      style={{
        minWidth: '1000px',
      }}
      {...modalProps}
      destroyOnClose={true}
      closable={false}
      footer={null}
    >
      <Container onSubmit={handleSubmit(onSubmit)}>
        <FormTitle>{`${isEdit ? 'Edit' : 'Create'} Notification Details`}</FormTitle>
        <FormContainer>
          <HorizontalAlignedContainer>
            <FieldLabel>Name:</FieldLabel>
            <InputContainer>
              <FieldInput hasError={!!errors.name} {...register('name', { required: true })} />
              {!!errors.name && <ErrorMessage>This field is required</ErrorMessage>}
            </InputContainer>
          </HorizontalAlignedContainer>
          <HorizontalAlignedContainer>
            <FieldLabel>Send Notification Email:</FieldLabel>
            <InputContainer>
              <Controller
                control={control}
                name="sendEmail"
                render={({ field }) => (
                  <Checkbox checked={field.value} onChange={(e) => field.onChange(e.target.checked)} />
                )}
              />
            </InputContainer>
          </HorizontalAlignedContainer>
          <HorizontalAlignedContainer>
            <FieldLabel>Title:</FieldLabel>
            <InputContainer>
              <FieldInput hasError={!!errors.title} {...register('title', { required: true })} />
              {!!errors.title && <ErrorMessage>This field is required</ErrorMessage>}
            </InputContainer>
          </HorizontalAlignedContainer>
          <HorizontalAlignedContainer>
            <FieldLabel>Content</FieldLabel>
          </HorizontalAlignedContainer>
          <HorizontalAlignedContainer>
            <InputContainer>
              <Controller
                control={control}
                name="content"
                rules={{ required: true }}
                render={({ field }) => (
                  <HtmlEditor
                    height={300}
                    enableMedia={true}
                    initialValue={field.value || ''}
                    onChange={(content) => setValue('content', content)}
                    onMediaSelected={handleMediaSelected}
                    onMediaNodesChange={currentMediaUrl}
                  />
                )}
              />
              {!!errors.content && <ErrorMessage>This field is required</ErrorMessage>}
            </InputContainer>
          </HorizontalAlignedContainer>
        </FormContainer>
        <HorizontalAlignedContainer
          style={{
            gap: '10px',
            justifyContent: 'center',
          }}
        >
          {isLoading ? (
            <Spin />
          ) : (
            <>
              <CancelButton type="button" onClick={handleCancel}>
                Cancel
              </CancelButton>
              <CreateButton>{isEdit ? 'Update' : 'Save'}</CreateButton>
              {(!isEdit || canPublish) && (
                <SaveAndPublishButton type="button" onClick={handleSubmit(handleSaveAndPublish)}>
                  {isEdit ? 'Update and Publish' : 'Save and Publish'}
                </SaveAndPublishButton>
              )}
            </>
          )}
        </HorizontalAlignedContainer>
      </Container>
    </Modal>
  );
}
