import { Select } from 'antd';
import React from 'react';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { TrendOverTime } from '@app/types/dashboardData';
import CustomTooltip from './CustomTooltip';
import moment from 'moment';
import { NoData } from '../common/NoData';
import { TimeFrameOptions } from '@app/utils/utils';

interface ILineChartCardProps {
  onSelectDateRangeCallback?: (value: TimeFrameOptions) => void;
  data?: TrendOverTime;
}

export default function LineChartCard({ data, onSelectDateRangeCallback }: ILineChartCardProps) {
  const chartData = !data
    ? []
    : data
        .slice()
        .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
        .map((entry) => ({
          total: 100,
          custom: entry.customScore,
          standard: entry.standardScore,
          name: moment(entry.date).format('MMM DD'),
        }));

  const renderLegend = (props: any) => {
    const { payload } = props;

    return (
      <div style={{ display: 'flex', gap: '16px', width: '100%', justifyContent: 'center', marginTop: '20px' }}>
        {payload.map((entry: any, index: number) => (
          <div
            key={`item-${index}`}
            style={{
              width: 'fit-content',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              fontSize: '0.75rem',
              fontWeight: 500,
              color: '#303030',
            }}
          >
            <div style={{ width: '15px', height: '15px', background: entry.payload.stroke, borderRadius: '4px' }} />
            {entry.dataKey === 'standard' ? 'Baseline Security Score' : 'Custom Security Score'}
          </div>
        ))}
      </div>
    );
  };

  const handleChange = (value: TimeFrameOptions) => {
    onSelectDateRangeCallback?.(value);
  };

  return (
    <div style={{ backgroundColor: 'white', padding: '16px', border: '1px solid #E9EAEB', borderRadius: '9px' }}>
      {/* HEADER */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '2lh',
          marginBottom: '8px',
        }}
      >
        <span style={{ fontSize: '1rem', fontWeight: 700 }}>Trend Over Time</span>
        <Select
          defaultValue="last30days"
          style={{ fontSize: '10px', padding: '0px' }}
          size="small"
          onChange={handleChange}
          options={[
            { value: 'last30days', label: 'Last 30 Days' },
            { value: 'last90days', label: 'Last 90 Days' },
            { value: 'last12months', label: 'Last 12 Months' },
            { value: 'alltime', label: 'All Time' },
          ]}
        />
      </div>
      <div style={{ height: '400px', width: '100%' }}>
        {!chartData || chartData.length === 0 ? (
          <NoData />
        ) : (
          <ResponsiveContainer width="100%" height="100%">
            <LineChart width={500} height={400} data={chartData}>
              <Legend iconType="square" content={renderLegend} />
              <CartesianGrid strokeDasharray="3 3" opacity={0.5} />
              <XAxis dataKey="name" dy={10} />
              <YAxis dataKey="total" type="number" tickFormatter={(tick) => `${tick}%`} />
              <Tooltip content={CustomTooltip} cursor={{ strokeDasharray: '20 20' }} />
              <Line
                type="linear"
                dataKey="custom"
                strokeWidth={3}
                stroke="#30AF5B"
                fill="#EAF7EF"
                opacity={0.5}
                dot={false}
              />
              <Line
                type="linear"
                dataKey="standard"
                strokeWidth={3}
                stroke="#8884d8"
                fill="#8884d8"
                opacity={0.5}
                dot={false}
              />
            </LineChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
}
