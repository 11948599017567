import useToggle from '@app/hooks/useToggle';
import { TAccountNotificationsForm } from './NotificationsForm';
import { TAccountNotifications } from '@app/types/accountNotifications';

interface IUseNotificationFormProps {
  onCancel?: () => void;
  onOk?: (data: TAccountNotificationsForm, type: string) => void;
  notifications?: TAccountNotifications;
}
export default function UseNotificationsForm(props?: IUseNotificationFormProps) {
  const [isModalOpen, toggle, setIsModalOpen] = useToggle();

  const handleOnOk = (data: TAccountNotificationsForm, type: string) => {
    props?.onOk?.(data, type);
  };

  const handleOnCancel = () => {
    props?.onCancel?.();
    setIsModalOpen(false);
  };
  return {
    open: isModalOpen,
    notifications: props?.notifications,
    toggle,
    onOk: handleOnOk,
    onCancel: handleOnCancel,
  };
}
