import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { MenuProps } from 'antd/lib/menu';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import moment from 'moment';
import { TDataCol, TDataColumns, TFilterValue, TGroupDataCol } from '../types';
import { ITableFiltersProps } from '../components/TableFitlers';
import { TExportExcel } from '@app/types/exportTypes';

const DEBOUNCE_TIMEOUT = 500;

export type TActionItems<T> =
  | {
      label: string;
      key: string;
      multiSelect: false;
      onClick: (selectedItem: T) => void;
      show?: (selectedItem: T) => boolean;
    }
  | {
      label: string;
      key: string;
      multiSelect: true;
      onClick: (selectedItems: T[]) => void;
      show?: (selectedItems: T[]) => boolean;
    };

interface IUseTableFiltersProps<T> {
  columns: TDataColumns<T>;
  defaultFilter?: TFilterValue[];
  defaultCheckedColumns?: TDataColumns<T>;
  selectedRows?: T[];
  actionItems?: TActionItems<T>[];
  exportExcel?: TExportExcel;
  importExcel?: { model: string };
  showTimeline?: boolean;
  onTimelineRangeChanged?: (newRange: { startDate: Date; endDate: Date } | undefined) => void;
  onFiltersChanged?: (
    filters?: TFilterValue[],
    orderBy?: { column: string; order: 'asc' | 'desc' },
    filterString?: string,
    columns?: TDataColumns<T>,
  ) => void;
  onSearch?: (searchValue: string) => void;
  onExport?: (exportType: TExportExcel) => void;
  onImport?: (file: File) => void;
}

export function useTableFilters<T>({
  columns,
  defaultFilter,
  defaultCheckedColumns,
  selectedRows,
  actionItems,
  exportExcel,
  importExcel,
  showTimeline,
  onTimelineRangeChanged,
  onFiltersChanged,
  onSearch,
  onExport,
  onImport,
}: IUseTableFiltersProps<T>): ITableFiltersProps<T> {
  const [searchColumnValue, setSearchColumnValue] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [selectedFilterColumn, setSelectedFilterColumn] = useState<TGroupDataCol<T> | TDataCol<T> | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [filters, setFilters] = useState<TFilterValue[]>(defaultFilter || []);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isImportConfirmationModalOpen, setIsImportConfirmationModalOpen] = useState(false);
  const [timelineRange, setTimelineRange] = useState<{ startDate: Date; endDate: Date } | undefined>({
    startDate: moment(new Date()).subtract(90, 'days').toDate(),
    endDate: moment(new Date()).toDate(),
  });

  const columnFilters: ItemType[] = useMemo(() => {
    return columns
      .filter((col) => col.allowFiltering)
      .map((col, index) => ({
        key: col.dataIndex as string,
        label: (typeof col.title == 'string' ? col.title : col.altLabel) as string,
      }));
  }, [columns]);

  const displayedFilters = useMemo(() => {
    if (searchColumnValue === '')
      return columnFilters
        .filter((col: any) => {
          if (filters.length === 0) {
            return true;
          }
          const isAlreadyApplied = filters.find((f) => f.column.toLowerCase() === col.key.toLowerCase()) ? true : false;

          if (isAlreadyApplied) {
            return false;
          }

          return true;
        })
        .sort((a: any, b: any) => {
          if (a.label > b.label) {
            return 1;
          }

          if (a.label < b.label) {
            return -1;
          }

          return 0;
        });

    return columnFilters
      .filter((col: any) => {
        if (filters.length === 0) {
          return col?.label.toLowerCase().indexOf(searchColumnValue.toLowerCase()) !== -1 ? true : false;
        } else {
          return (
            col?.label.toLowerCase().indexOf(searchColumnValue.toLowerCase()) !== -1 &&
            filters.find((f) => (f.column.toLowerCase() !== col.key.toLowerCase() ? true : false))
          );
        }
      })
      .sort((a: any, b: any) => {
        if (a.label > b.label) {
          return 1;
        }

        if (a.label < b.label) {
          return -1;
        }

        return 0;
      });
  }, [columnFilters, searchColumnValue, filters]);

  const hasSearchFunctionality = useMemo(() => {
    if (onSearch) return true;

    return false;
  }, [onSearch]);

  useEffect(() => {
    const delayedSearch = setTimeout(() => {
      onSearch?.(searchValue);
    }, DEBOUNCE_TIMEOUT);

    return () => clearTimeout(delayedSearch);
  }, [searchValue]);

  useEffect(() => {
    if (!!defaultFilter) {
      setFilters(defaultFilter);
    }
  }, [defaultFilter]);

  useEffect(() => {
    onTimelineRangeChanged?.(timelineRange);
  }, [timelineRange]);

  useEffect(() => {
    // clear applied filters if there is no filter search param on the url
    if (!new URLSearchParams(window.location.search).get('$filter')) {
      setFilters([]);
    }
  }, [window.location.search]);

  const onFilterOptionClicked: MenuProps['onClick'] = (e) => {
    //to stop the closing of dropdown when an item is clicked
    e.domEvent.stopPropagation();

    const filterLabel = (columnFilters.find((col) => col?.key?.toString() === e.key) as any)?.label;
    const selectedColumn = columns.find(
      (col) => col.title === filterLabel || col.altLabel === filterLabel,
    ) as TDataCol<T>;

    setSelectedFilterColumn(selectedColumn);
    setSearchColumnValue('');
  };

  const onSearchColumn = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchColumnValue(e.target.value);
  };

  const toggleDropdown = (open: boolean) => {
    setIsDropdownOpen(open);

    if (open === true) {
      setSelectedFilterColumn(null);
      setSearchColumnValue('');
    }
  };

  const onBackClicked = () => {
    setSelectedFilterColumn(null);
    setSearchColumnValue('');
  };

  const onApply = (filterObj: TFilterValue) => {
    const newFilters = [...filters];

    const filterIndex = filters.findIndex(
      (filter) => filter.column === filterObj.column && filter.type === filterObj.type,
    );
    if (filterIndex > -1) {
      newFilters[filterIndex] = filterObj;
    } else {
      newFilters.push(filterObj);
    }

    const filteredNewFilters = newFilters.filter((f) =>
      f.type === 'enum' || f.type === 'textOptions' || f.type === 'enumArray'
        ? f.value !== '[]'
          ? true
          : false
        : true,
    );

    toggleDropdown(false);
    setFilters(filteredNewFilters);
    onFiltersChanged?.(filteredNewFilters);
  };

  const onFilterRemove = (index: number) => {
    const newFilters = filters.filter((f, fIndex) => fIndex !== index);
    setFilters(newFilters);
    onFiltersChanged?.(newFilters);
  };

  const onClearFilters = () => {
    if (filters.length <= 0) return;

    setFilters([]);
    onFiltersChanged?.([]);
  };

  const toggleModal = (open: boolean) => {
    setIsModalOpen(open);
  };

  const toggleExportModal = (open: boolean) => {
    setIsExportModalOpen(open);
  };

  const toggleImportConfirmationModal = (open: boolean) => {
    setIsImportConfirmationModalOpen(open);
  };

  const onChangeSearchValue = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  return {
    columns,
    defaultCheckedColumns,
    columnFilters: displayedFilters,
    appliedFilters: filters,
    searchColumnValue,
    selectedFilterColumn,
    isDropdownOpen,
    isModalOpen,
    isExportModalOpen,
    isImportConfirmationModalOpen,
    searchValue,
    hasSearchFunctionality,
    selectedRows,
    actionItems,
    exportExcel,
    importExcel,
    showTimeline,
    timelineRange,
    setTimelineRange,
    onChangeSearchValue,
    onFilterOptionClicked,
    onSearchColumn,
    toggleDropdown,
    onBackClicked,
    onApply,
    onFilterRemove,
    onClearFilters,
    toggleModal,
    toggleExportModal,
    toggleImportConfirmationModal,
    onExport,
    onImport,
    onFiltersChanged,
  };
}
