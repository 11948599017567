import { Node } from '@tiptap/core';

export interface LoadingNodeOptions {
  HTMLAttributes: Record<string, any>;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    loading: {
      /**
       * Insert a loading node
       */
      setLoading: () => ReturnType;
      /**
       * Remove the loading node
       */
      removeLoading: () => ReturnType;
    };
  }
}

export const LoadingNode = Node.create<LoadingNodeOptions>({
  name: 'loading',

  group: 'block',

  atom: true,

  addAttributes() {
    return {
      class: {
        default: 'media-loading',
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'div.media-loading',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', { ...HTMLAttributes, class: 'media-loading' }, 'Uploading...'];
  },

  addCommands() {
    return {
      setLoading:
        () =>
        ({ commands }) => {
          return commands.insertContent('<div class="media-loading" />Uploading...</div>');
        },

      removeLoading:
        () =>
        ({ tr, dispatch, state }) => {
          let transactionApplied = false;

          // check editor to find the 'loading node
          state.doc.descendants((node, pos) => {
            if (node.type.name === 'loading') {
              const transaction = tr.delete(pos, pos + node.nodeSize);

              // if loading node is found, delete it
              if (dispatch) {
                dispatch(transaction);
                transactionApplied = true;
              }
            }
          });

          return transactionApplied;
        },
    };
  },
});
