import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useTranslation } from 'react-i18next';
import ComponentHeader from '@app/components/common/ComponentHeader/ComponentHeader';
import CreateAlertButton from '@app/components/apps/recommendations/CreateAlertButton';
import UseNotificationsForm from '@app/components/apps/manage-notifications/useNotificationsForm';
import { TDataColumns } from '@app/components/tables/AntdTableWrapper/types';
import { useDataTable } from '@app/components/tables/AntdTableWrapper/hooks/useDataTable';
import { TActionItems } from '@app/components/tables/AntdTableWrapper/hooks/useTableFilters';
import { DataTable } from '@app/components/tables/AntdTableWrapper';
import { TAccountNotifications } from '@app/types/accountNotifications';
import { getEnumValue } from '@app/services/enum.service';
import { useState } from 'react';
import NotificationsForm, {
  TAccountNotificationsForm,
} from '@app/components/apps/manage-notifications/NotificationsForm';
import {
  useCreateAccountNotifications,
  useEditAccountNotifications,
  useDeleteNotification,
  usePublishNotification,
} from '@app/api/accountNotification.api';
import ActionConfirmationModal from '@app/components/apps/manage-users/ActionConfirmationModal';
import { convertDateToReadablePastDate, uploadMediaToStorage } from '@app/utils/utils';
import NotificationStatusBadge from '@app/components/apps/manage-notifications/components/NotificationStatusBadge';

export default function ManageNotificationsPage() {
  const { t } = useTranslation();
  const [isEdit, setIsEdit] = useState(false);
  const [selectedNotification, setselectedNotification] = useState<TAccountNotifications | undefined>(undefined);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [mediaFiles, setMediaFiles] = useState<{ file: File; previewUrl: string }[]>([]);
  const [currentMediaUrls, setCurrentMediaUrls] = useState<string[]>([]);
  const [mediaFilesLoading, setMediaFilesLoading] = useState(false);
  const { mutateAsync: createAccountNotifications, isLoading: isCreating } = useCreateAccountNotifications();
  const { mutateAsync: editAccountNotifications, isLoading: isUpdating } = useEditAccountNotifications();
  const { mutateAsync: deleteNotification } = useDeleteNotification();
  const { mutateAsync: publishNotification, isLoading: isPublishing } = usePublishNotification();

  const handleModalClose = () => {
    setselectedNotification(undefined);
    setIsConfirmationModalOpen(false);
  };

  const handleDeleteConfirmation = async () => {
    if (selectedNotification) {
      await deleteNotification(selectedNotification.notificationId);
      handleModalClose();
      dataTableProps.refetch();
      dataTableProps.clearSelection();
    }
  };

  const handleSaveAndPublish = async (data: TAccountNotificationsForm) => {
    const createdNotification = selectedNotification
      ? await publishNotification(selectedNotification.notificationId)
      : await createAccountNotifications(data);

    if (createdNotification?.id) {
      await publishNotification(createdNotification.id);
      dataTableProps.refetch();
    }
    dataTableProps.refetch();
  };

  const handleMediaSelected = (files: File[], previews: string[]) => {
    const newMedia = files.map((file, index) => ({
      file,
      previewUrl: previews[index],
    }));
    setMediaFiles((prevMedia) => [...prevMedia, ...newMedia]);
  };

  const notificationColumns: TDataColumns<TAccountNotifications> = [
    {
      title: 'Name',
      dataIndex: 'name',
      allowFiltering: true,
      type: 'text',
      width: '4vw',
    },
    //Todo - restore this field after solving data-binding issue
    // {
    //   title: 'Time Sent',
    //   dataIndex: 'timeSent',
    //   allowFiltering: true,
    //   type: 'datetime',
    //   width: '4vw',
    //   align: 'center',
    //   render: (_, record) => {
    //     if (!record.timeSent) {
    //       return '-';
    //     }
    //     const convertedTimeSent = convertDateToReadablePastDate(record.timeSent as unknown as string, 'datetime');
    //     return convertedTimeSent;
    //   },
    // },
    {
      title: 'Creation Time',
      dataIndex: 'creationTime',
      allowFiltering: true,
      type: 'datetime',
      align: 'center',
      width: '2vw',
    },
    {
      title: 'Last Updated',
      dataIndex: 'lastUpdateTime',
      allowFiltering: true,
      type: 'datetime',
      align: 'center',
      width: '2vw',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      allowFiltering: true,
      type: 'enum',
      enumValuesKey: 'NotificationStatus',
      width: '2vw',
      align: 'center',
      render: (_, record) => {
        return (
          <NotificationStatusBadge type={(record.status as unknown as string).toLowerCase() as any}>
            {record.status}
          </NotificationStatusBadge>
        );
      },
    },
  ];

  const actionItems: TActionItems<TAccountNotifications>[] = [
    {
      key: 'edit',
      label: 'Edit',
      multiSelect: false,
      onClick: (data) => {
        setIsEdit(true);
        setselectedNotification(data);
        toggle();
      },
    },
    {
      key: 'delete',
      label: 'Delete',
      multiSelect: false,
      onClick: (data) => {
        setselectedNotification(data);
        setIsConfirmationModalOpen(true);
      },
    },
  ];

  const processMediaFiles = async (payload: TAccountNotificationsForm) => {
    setMediaFilesLoading(true);
    const filesToUpload = mediaFiles.filter((media) => currentMediaUrls.includes(media.previewUrl));

    if (filesToUpload.length > 0) {
      const files = filesToUpload.map((media) => media.file);
      const previews = filesToUpload.map((media) => media.previewUrl);
      const newUrls = await uploadMediaToStorage(files, 'assets');

      if (newUrls && newUrls.length === previews.length) {
        newUrls.forEach((newUrl, index) => {
          const oldUrl = previews[index];
          if (oldUrl && newUrl) {
            payload.content = payload.content.replace(new RegExp(oldUrl, 'g'), newUrl);
          }
        });
      }
    }
    setMediaFilesLoading(false);
    return payload;
  };

  const { toggle, ...notificationFormProps } = UseNotificationsForm({
    onOk: async (data, isSaveAndPublish) => {
      let payload = { ...data };
      if (mediaFiles.length > 0) {
        payload = await processMediaFiles(payload);
      }

      if (isSaveAndPublish) {
        await handleSaveAndPublish(payload);
      } else if (isEdit && selectedNotification) {
        await editAccountNotifications({
          id: selectedNotification.notificationId,
          data: payload,
        });
      } else {
        await createAccountNotifications(payload);
      }

      setIsEdit(false);
      setselectedNotification(undefined);
      toggle();
      dataTableProps.refetch();
    },
    onCancel: () => {
      setIsEdit(false);
      setselectedNotification(undefined);
    },
    notifications: selectedNotification,
  });

  const dataTableProps = useDataTable<TAccountNotifications>({
    model: 'notification',
    stickyHeader: true,
    columns: notificationColumns,
    searchableColumns: ['name'],
    actionItems,
    constantFilter: `status eq ${getEnumValue('NotificationStatus', 'Enabled')} or status eq ${getEnumValue(
      'NotificationStatus',
      'Draft',
    )}`,
    defaultOrder: {
      column: 'name',
      order: 'asc',
    },
    onRowClick: (data) => {
      setIsEdit(true);
      setselectedNotification(data);
      toggle();
    },
  });

  return (
    <>
      <PageTitle>{String(t('common.manageNotifications'))}</PageTitle>
      <ComponentHeader
        title={t('common.manageNotifications')}
        subTitle="Create notifications to be displayed to users."
        subTitleFullWidth={true}
        actionButtons={<>{<CreateAlertButton title="Create Notification" onPress={toggle} />}</>}
      />
      <DataTable {...dataTableProps} />
      <NotificationsForm
        {...notificationFormProps}
        isEdit={isEdit}
        isLoading={isCreating || isUpdating || isPublishing || mediaFilesLoading}
        handleMediaSelected={handleMediaSelected}
        currentMediaUrl={setCurrentMediaUrls}
      />
      <ActionConfirmationModal
        open={isConfirmationModalOpen}
        onOk={handleDeleteConfirmation}
        onCancel={handleModalClose}
        message="Are you sure you want to delete this Notification?"
      />
    </>
  );
}
