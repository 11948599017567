import Image from '@tiptap/extension-image';
import { mergeAttributes } from '@tiptap/core';

interface ExtendedImageOptions {
  src: string;
  alt?: string;
  title?: string;
  width?: string;
  height?: string;
}

export const ExtendedImage = Image.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      width: {
        default: '400px',
        parseHTML: (element) => element.getAttribute('width'),
        renderHTML: (attributes) => ({ width: attributes.width }),
      },
      height: {
        default: 'auto',
        parseHTML: (element) => element.getAttribute('height'),
        renderHTML: (attributes) => ({ height: attributes.height }),
      },
      loading: {
        default: 'lazy',
      },
      placeholder: {
        default: 'Image Loading...',
      },
    };
  },

  addCommands() {
    return {
      setImage:
        (options: ExtendedImageOptions) =>
        ({ state, dispatch }) => {
          const { schema, selection } = state;
          const position = selection.$to.pos;

          const node = schema.nodes.image.create({
            src: options.src,
            alt: options.alt,
            title: options.title,
            width: options.width || '400px',
            height: options.height || 'auto',
          });

          const transaction = state.tr.insert(position, node);

          if (dispatch) {
            dispatch(transaction);
          }

          return true;
        },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'img',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        'data-placeholder': HTMLAttributes.placeholder || 'Image Loading...',
        onload: "this.removeAttribute('data-placeholder')",
        onerror: "this.setAttribute('alt', 'Image failed to load')",
      }),
    ];
  },
});
