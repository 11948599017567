import { Node, nodeInputRule } from '@tiptap/core';
import { Plugin, PluginKey } from 'prosemirror-state';

export interface VideoOptions {
  HTMLAttributes: Record<string, any>;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    video: {
      /**
       * Set a video node
       */
      setVideo: (videoOptions: { src: string; width?: string; height?: string }) => ReturnType;
      /**
       * Toggle a video node
       */
      toggleVideo: () => ReturnType;
    };
  }
}

const VIDEO_INPUT_REGEX = /!\[(.+|:?)]\((\S+)(?:(?:\s+)["'](\S+)["'])?\)/;

export const Video = Node.create<VideoOptions>({
  name: 'video',

  group: 'block',

  atom: true,

  addAttributes() {
    return {
      src: {
        default: null,
        parseHTML: (el) => (el as HTMLVideoElement).getAttribute('src'),
        renderHTML: (attrs) => ({ src: attrs.src }),
      },
      width: {
        default: 'auto',
        rendered: true,
        parseHTML: (element) => element.getAttribute('width'),
        renderHTML: (attributes) => ({ width: attributes.width }),
      },
      height: {
        default: null,
        rendered: true,
        parseHTML: (element) => element.getAttribute('height'),
        renderHTML: (attributes) => ({ height: attributes.height }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'video',
        getAttrs: (el) => ({ src: (el as HTMLVideoElement).getAttribute('src') }),
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['video', { controls: 'true', ...HTMLAttributes }, ['source', HTMLAttributes]];
  },

  addCommands() {
    return {
      setVideo:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: options,
          });
        },

      toggleVideo:
        () =>
        ({ commands }) => {
          return commands.toggleNode(this.name, 'paragraph');
        },
    };
  },

  addInputRules() {
    return [
      nodeInputRule({
        find: VIDEO_INPUT_REGEX,
        type: this.type,
        getAttributes: (match) => {
          const [, , src] = match;
          return { src };
        },
      }),
    ];
  },

  // WIP
  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('videoDropPlugin'),

        props: {
          handleDOMEvents: {
            drop(view, event) {
              const {
                state: { schema, tr },
                dispatch,
              } = view;
              const hasFiles = event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files.length;

              if (!hasFiles) return false;

              const videos = Array.from(event.dataTransfer.files).filter((file) => /video/i.test(file.type));

              if (videos.length === 0) return false;

              event.preventDefault();

              const coordinates = view.posAtCoords({ left: event.clientX, top: event.clientY });

              videos.forEach((video) => {
                const reader = new FileReader();

                reader.onload = (readerEvent) => {
                  const node = schema.nodes.video.create({ src: readerEvent.target?.result });

                  if (coordinates && typeof coordinates.pos === 'number') {
                    const transaction = tr.insert(coordinates?.pos, node);
                    dispatch(transaction);
                  }
                };

                reader.readAsDataURL(video);
              });

              return true;
            },
          },
        },
      }),
    ];
  },
});
