import React from 'react';
import * as S from './NotificationFiltersStyle';

interface NotificationFiltersProps {
  filter: string;
  unreadCount: number;
  onFilterChange: (newFilter: 'all' | 'unread' | 'hidden') => void;
}

export const NotificationFilters: React.FC<NotificationFiltersProps> = ({ filter, unreadCount, onFilterChange }) => {
  return (
    <S.FiltersContainer>
      <S.FilterWrapper>
        <S.LinkText active={filter === 'all'} onClick={() => onFilterChange('all')}>
          All
          {unreadCount > 0 && <S.NotificationBadge active={filter === 'all'}>{unreadCount}</S.NotificationBadge>}
        </S.LinkText>
      </S.FilterWrapper>
      <S.FilterWrapper>
        <S.LinkText active={filter === 'unread'} onClick={() => onFilterChange('unread')}>
          Unread
          {unreadCount > 0 && <S.NotificationBadge active={filter === 'unread'}>{unreadCount}</S.NotificationBadge>}
        </S.LinkText>
      </S.FilterWrapper>
      <S.FilterWrapper>
        <S.LinkText active={filter === 'hidden'} onClick={() => onFilterChange('hidden')}>
          Archived
        </S.LinkText>
      </S.FilterWrapper>
    </S.FiltersContainer>
  );
};
