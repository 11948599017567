import { SorterResult } from 'antd/lib/table/interface';
import { format } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  useExportModelToExcel,
  useExportToExcel,
  useGetTableModelItems,
  useImportModelFromExcel,
} from '@app/api/table.api';
import { TList } from '@app/types/generalTypes';
import {
  constructFilterStateFromUrl,
  constructUrlFromState,
  convertSelectUrlToColumns,
  replaceSpecialChars,
} from '@app/utils/utils';
import { TDataColumns, TFilterValue } from '../types';
import { usePagination } from './usePagination';
import { TActionItems, useTableFilters } from './useTableFilters';
import { useMounted } from '@app/hooks/useMounted';
import { TExportExcel } from '@app/types/exportTypes';

const TOP_PARAMS_KEY = '$top';
const SKIP_PARAMS_KEY = '$skip';
const FILTER_PARAMS_KEY = '$filter';
const SELECT_PARAMS_KEY = '$select';
const ADDED_FILTERS_KEY = '$added';
const ORDER_BY_PARAMS_KEY = '$navorder';

const DEFAULT_LIMIT_INTERVAL = [10, 25, 50];
const DEFAULT_SKIP = '0';
const DEFAULT_LIMIT = '10';
const DEFAULT_IS_MOCK = false;
const DEFAULT_TOTAL_ROWS = 0;

export type TQueryParams = {
  top?: number;
  skip?: number;
  filterValues: TFilterValue[];
  orderBy?: {
    column: string;
    order: 'asc' | 'desc' | string;
  };
  expand?: string[];
};

interface IUseDataTableProps<T extends { id: number | string }> {
  model?: string;
  endpoint?: string;
  customQueryKey?: string;
  queryFn?: (queryString: string) => Promise<TList<T>>;
  columns: TDataColumns<T>;
  // if true, then the table does not read/write URL query params
  noUrl?: boolean;
  limit?: number;
  skip?: number;
  limitIntervals?: number[];
  isMock?: boolean;
  maxHeight?: number;
  searchableColumns?: string[];
  constantFilter?: string;
  defaultOrder?: TQueryParams['orderBy'];
  expand?: TQueryParams['expand'];
  actionItems?: TActionItems<T>[];
  trigger?: boolean;
  exportExcel?: TExportExcel;
  importExcel?: { model: string };
  showHeader?: boolean;
  showTimeline?: boolean;
  emptyRowText?: string;
  stickyHeader?: boolean;
  onTimelineRangeChanged?: (newRange: { startDate: Date; endDate: Date } | undefined) => void;
  onFetch?: (response: TList<T>) => void;
  onRowClick?: (data: T, index: number | undefined) => void;
  onRowMouseEnter?: (data: T, index: number | undefined) => void;
  onRowMouseLeave?: (data: T, index: number | undefined) => void;
  onSelectRows?: (selectedRows: T[]) => void;
  onFiltersChangeCallback?: (filters: TFilterValue[]) => void;
}

export function useDataTable<T extends { id: number | string }>({
  model,
  endpoint,
  customQueryKey,
  queryFn,
  columns,
  noUrl = false,
  limit = noUrl
    ? parseInt(DEFAULT_LIMIT)
    : parseInt(new URLSearchParams(window.location.search).get(TOP_PARAMS_KEY) || DEFAULT_LIMIT),
  skip = noUrl
    ? parseInt(DEFAULT_SKIP)
    : parseInt(new URLSearchParams(window.location.search).get(SKIP_PARAMS_KEY) || DEFAULT_SKIP),
  limitIntervals = DEFAULT_LIMIT_INTERVAL,
  isMock = DEFAULT_IS_MOCK,
  maxHeight,
  searchableColumns,
  constantFilter,
  defaultOrder,
  actionItems,
  trigger,
  exportExcel = {
    type: 'Standard',
    model: '',
  },
  importExcel,
  showHeader,
  showTimeline,
  emptyRowText,
  stickyHeader,
  onRowClick,
  onRowMouseEnter,
  onRowMouseLeave,
  onSelectRows,
  onFetch,
  onFiltersChangeCallback,
  onTimelineRangeChanged,
  expand,
}: IUseDataTableProps<T>) {
  const navigate = useNavigate();
  const { isMounted } = useMounted();
  const { mutateAsync: exportToExcel } = useExportToExcel();
  const { mutateAsync: exportModelToExcel } = useExportModelToExcel();
  const { mutateAsync: importModelFromExcel } = useImportModelFromExcel();

  const defaultFilterValues = useMemo(() => {
    if (
      noUrl ||
      ((!new URLSearchParams(window.location.search).get(FILTER_PARAMS_KEY) ||
        new URLSearchParams(window.location.search).get(FILTER_PARAMS_KEY) === '') &&
        (!new URLSearchParams(window.location.search).get(ADDED_FILTERS_KEY) ||
          new URLSearchParams(window.location.search).get(ADDED_FILTERS_KEY) === ''))
    ) {
      return undefined;
    }

    return constructFilterStateFromUrl(new URLSearchParams(window.location.search), columns);
  }, [window.location.search]);

  const defaultSelectValues = useMemo(() => {
    if (noUrl) {
      return columns.filter((col) => !col.hideColumn);
    }

    if (new URLSearchParams(window.location.search).get(SELECT_PARAMS_KEY) === '') {
      return undefined;
    }

    return convertSelectUrlToColumns(columns);
  }, [window.location.search]);

  const defaultOrderBy = useMemo(() => {
    if (new URLSearchParams(window.location.search).get(ORDER_BY_PARAMS_KEY) === '') {
      return undefined;
    }

    return JSON.parse(new URLSearchParams(window.location.search).get(ORDER_BY_PARAMS_KEY)!) as TQueryParams['orderBy'];
  }, [window.location.search]);

  const [columnsState, setColumnsState] = useState(columns);
  const [queryParams, setQueryParams] = useState<TQueryParams>({
    top: limit,
    skip: skip,
    orderBy: defaultOrder || defaultOrderBy || undefined,
    filterValues: defaultFilterValues || [],
    expand: expand || undefined,
  });
  const [selectedRows, setSelectedRows] = useState<T[]>([]);

  const andtDTableCols = useMemo(() => {
    return columnsState
      .filter((col) => col.hideColumn !== true)
      .map((col) => {
        let newColumn = { ...col };

        if (col.showSortDirections) {
          newColumn = { ...col, sorter: (a: any, b: any) => 0 };
        } else {
          newColumn = { ...col, sorter: false };
        }

        return newColumn;
      });
  }, [columnsState]);

  const hasFilters = useMemo(() => {
    return columnsState.filter((col) => col.allowFiltering === true).length > 0;
  }, [columnsState]);

  const exposedQueryString = useMemo(() => {
    return constructUrlFromState(queryParams, columnsState);
  }, []);

  const queryString = useMemo(() => {
    const { top, skip, orderBy, filterValues, expand } = queryParams;

    const allFilters = filterValues
      .filter((f) => f.type !== 'searchableColumn')
      .sort((a, b) => (a.type === 'text' || a.type === 'textOptions' ? -1 : 1))
      .map((f) => {
        if (f.type === 'number') return `(${f.column} ${f.value})`;

        if (f.type === 'boolean') return `(${f.column} eq ${f.value})`;

        if (f.type === 'enum') {
          let value: number[] = JSON.parse(f.value);
          value = (Array.isArray(value) ? value : [value]) as number[];
          return `(${(value as number[]).map((val) => `${f.column} eq ${val}`).join(' or ')})`;
        }

        if (f.type === 'enumArray') {
          return `(${(JSON.parse(f.value) as number[]).map((val) => `contains(${f.column},'-${val}-')`).join(' or ')})`;
        }

        if (f.type === 'textOptions' && !!f.multipleSelect) {
          return `(${(JSON.parse(f.value) as string[])
            .map((val) => `contains(tolower(${f.column}),'${val}')`)
            .join(' or ')})`;
        }

        if (f.type === 'date') {
          const dates = JSON.parse(f.value);
          return `(${f.column} ge ${format(dates[0], 'yyyy-MM-dd')} and ${f.column} le ${format(
            dates[1],
            'yyyy-MM-dd',
          )})`;
        }

        return `(contains(tolower(${f.column}),'${replaceSpecialChars(f.value.toLowerCase().replaceAll("''", "'"))}'))`;
      })
      .join(' and ');

    const concatenatedFilters = [allFilters, constantFilter].filter((f) => f !== '' && f !== undefined).join(' and ');

    return `${concatenatedFilters !== '' ? `$filter=${concatenatedFilters}&` : ''}$top=${top}&$skip=${skip}${
      orderBy ? `&$orderby=${orderBy.column} ${orderBy.order}` : ''
    }${expand && expand.length > 0 ? `&$expand=${expand.join(',')}` : ''}`;
  }, [queryParams, constantFilter]);

  const filtersOnlyQueryString = useMemo(() => {
    const { filterValues } = queryParams;

    const allFilters = filterValues
      .filter((f) => f.type !== 'searchableColumn')
      .sort((a, b) => (a.type === 'text' || a.type === 'textOptions' ? -1 : 1))
      .map((f) => {
        if (f.type === 'number') return `(${f.column} ${f.value})`;

        if (f.type === 'boolean') return `(${f.column} eq ${f.value})`;

        if (f.type === 'enumArray') {
          return `(${(JSON.parse(f.value) as number[]).map((val) => `contains(${f.column},'-${val}-')`).join(' or ')})`;
        }

        if (f.type === 'enum') {
          let value: number[] = JSON.parse(f.value);
          value = (Array.isArray(value) ? value : [value]) as number[];

          return `(${(value as number[]).map((val) => `${f.column} eq ${val}`).join(' or ')})`;
        }

        if (f.type === 'textOptions' && !!f.multipleSelect) {
          return `(${(JSON.parse(f.value) as string[])
            .map((val) => `contains(tolower(${f.column}),'${val}')`)
            .join(' or ')})`;
        }

        if (f.type === 'date') {
          const dates = JSON.parse(f.value);
          return `(${f.column} ge ${format(dates[0], 'yyyy-MM-dd')} and ${f.column} le ${format(
            dates[1],
            'yyyy-MM-dd',
          )})`;
        }

        return `(contains(tolower(${f.column}),'${replaceSpecialChars(f.value.toLowerCase().replaceAll("''", "'"))}'))`;
      })
      .join(' and ');

    const concatenatedFilters = [allFilters, constantFilter].filter((f) => f !== '' && f !== undefined).join(' and ');

    return `${concatenatedFilters !== '' ? `$filter=${concatenatedFilters}` : ''}`;
  }, [queryParams, constantFilter]);

  const searchableColumnQueryString = useMemo(() => {
    const { filterValues } = queryParams;
    const searchableColumnsFilters = filterValues
      .filter((f) => f.type === 'searchableColumn')
      .map((f) => `(contains(tolower(${f.column}),'${f.value.toLowerCase()}'))`)
      .join(' or ');

    if (searchableColumnsFilters === '') {
      return undefined;
    }

    return searchableColumnsFilters;
  }, [queryParams]);

  const {
    data,
    refetch: getItems,
    isFetching,
  } = useGetTableModelItems({
    model,
    endpoint,
    customQueryKey,
    queryFn,
    columns,
    queryParams: queryString,
    searchQueryParam: searchableColumnQueryString,
    trigger,
  });

  const { resetToDefaults, ...paginationProps } = usePagination({
    totalRows: DEFAULT_TOTAL_ROWS,
    limit,
    skip,
    limitIntervals,
    onNext: ({ limit, skip }) => {
      setQueryParams({ ...queryParams, top: limit, skip: skip });
    },
    onPrev: ({ limit, skip }) => {
      setQueryParams({ ...queryParams, top: limit, skip: skip });
    },
    onPageClicked: ({ limit, skip }) => {
      setQueryParams({ ...queryParams, top: limit, skip: skip });
    },
    onLimitChanged: ({ limit, skip }) => {
      setQueryParams({ ...queryParams, top: limit, skip: skip });
    },
  });

  const filterProps = useTableFilters<T>({
    columns: columnsState,
    defaultFilter: defaultFilterValues,
    defaultCheckedColumns: defaultSelectValues as any,
    selectedRows,
    actionItems,
    exportExcel,
    importExcel,
    showTimeline,
    onFiltersChanged,
    onTimelineRangeChanged,
    onSearch: searchableColumns && searchableColumns.length > 0 ? handleSearch : undefined,
    onExport: handleExport,
    onImport: handleImport,
  });

  const onSort = (sortProps: SorterResult<T>) => {
    const newOrderBy: any = !sortProps.order
      ? {
          column: undefined,
          order: undefined,
        }
      : {
          column: sortProps.field?.toString() || '',
          order: sortProps.order === 'ascend' ? 'asc' : 'desc',
        };

    setQueryParams({
      ...queryParams,
      orderBy: newOrderBy,
    });

    onFiltersChanged(undefined, newOrderBy, undefined, undefined);
  };

  function onFiltersChanged(
    _filters?: TFilterValue[],
    orderBy?: { column: string; order: 'asc' | 'desc' },
    filterString?: string,
    _columns?: TDataColumns<T>,
  ) {
    const columns = _columns || columnsState;
    resetToDefaults();
    const searchableColumnsFilters = queryParams.filterValues.filter((f) => f.type === 'searchableColumn');
    const filters = _filters ? [...searchableColumnsFilters, ..._filters] : queryParams.filterValues;

    let newQueryParams = {
      ...queryParams,
      top: limit,
      skip: 0, // force to page 1
      filterValues: filters,
      ...(orderBy && { orderBy }),
    };

    let newColumnState = [...columns];

    if (orderBy) {
      if (!orderBy.column) {
        newQueryParams = { ...newQueryParams, orderBy: undefined };
      } else {
        newColumnState = newColumnState.map((col) => {
          if (col.dataIndex === orderBy?.column) {
            return {
              ...col,
              defaultSortOrder: orderBy?.order === 'asc' ? 'ascend' : 'descend',
            };
          }

          return col;
        }) as TDataColumns<T>;
      }
    }
    setQueryParams(newQueryParams);

    if (filterString) {
      const selectParamArray = filterString
        .replace('$filter=', '')
        .split(/\sand\s|\&\$/)
        .find((f) => f.includes('select'))
        ?.replace('select=', '')
        .split(',');

      newColumnState = newColumnState.map((col) => {
        if (!selectParamArray?.includes(col.dataIndex as string)) {
          return { ...col, hideColumn: true };
        }

        return col;
      });
    }

    setColumnsState(newColumnState);
    onFiltersChangeCallback?.(filters);

    const queryString = constructUrlFromState(newQueryParams, newColumnState);
    navigate(`?${queryString}`);
  }

  function handleSearch(searchValue: string) {
    if (searchValue === '') {
      const newFilterValues = queryParams.filterValues.filter((f) => f.type !== 'searchableColumn');
      setQueryParams({ ...queryParams, filterValues: newFilterValues });
      return;
    }

    if (searchValue.length <= 2) {
      return;
    }

    /**
     * odata server cant handle single quotation marks on the search.
     * as a solution, we append another single quotation mark so the search works.
     */
    const modifiedSearchValue = replaceSpecialChars(searchValue);

    const filterValues = [...queryParams.filterValues];

    for (const col of searchableColumns!) {
      const colIndex = filterValues.findIndex((fv) => fv.column === col && fv.type === 'searchableColumn');

      if (colIndex > -1) {
        filterValues[colIndex] = { ...filterValues[colIndex], value: modifiedSearchValue };
      } else {
        filterValues.push({
          value: modifiedSearchValue,
          column: col,
          type: 'searchableColumn',
        });
      }
    }

    setQueryParams({ ...queryParams, filterValues });
  }

  async function handleExport(exportType: TExportExcel) {
    const selectedFields = columnsState
      .filter((col) => !!col.dataIndex && !col.hideColumn)
      .map((col) => ({
        name: col.dataIndex,
        displayName: col.title,
      })) as { name: string; displayName: string }[];

    const filters =
      filtersOnlyQueryString && filtersOnlyQueryString !== ''
        ? filtersOnlyQueryString.replace('$filter=', '')
        : undefined;

    const sortOrder = queryParams.orderBy?.column
      ? {
          fieldName: queryParams.orderBy?.column as string,
          order: queryParams.orderBy?.order as 'asc' | 'desc',
        }
      : undefined;

    try {
      if (exportType.type === 'Standard') {
        const standardResponse = await exportToExcel({
          modelName: model as string,
          filters,
          sortOrder,
          selectedFields,
        });
      } else if (exportType.type === 'Model') {
        const modelToExcelResponse = await exportModelToExcel({
          modelName: exportType.model || '',
          viewName: model as string,
          filters,
          sortOrder,
          selectedFields,
        });
      }
    } catch (error) {
      throw new Error('Failed to export data');
    }
  }

  async function handleImport(file: File) {
    const importResponse = await importModelFromExcel({
      modelName: importExcel?.model || '',
      file: file,
    });
  }

  function clearSelection() {
    setSelectedRows([]);
  }

  function changeFiltersAndColumns(newFilters: TFilterValue[], newCols: TDataColumns<T>) {
    setColumnsState(newCols);
    setQueryParams({ ...queryParams, filterValues: newFilters });

    onFiltersChanged(newFilters, undefined, undefined, newCols);
  }

  useEffect(() => {
    getItems();
  }, [queryParams, columnsState]);

  useEffect(() => {
    if (data) {
      onFetch?.(data);
      paginationProps.setTotalRows(data.count);
    }
  }, [data]);

  useEffect(() => {
    if (defaultSelectValues && defaultSelectValues?.length > 0 && isMounted.current) {
      const defaultSelectKeys = defaultSelectValues.map((val) => val?.dataIndex);
      const newColumnState = columnsState.map((col) => {
        return { ...col, hideColumn: col.dataIndex && !defaultSelectKeys.includes(col.dataIndex) ? true : false };
      });
      setColumnsState(newColumnState);
    }
  }, [isMounted.current]);

  useEffect(() => {
    if (!defaultFilterValues) return;

    setQueryParams({ ...queryParams, filterValues: defaultFilterValues });
  }, [defaultFilterValues]);

  useEffect(() => {
    if (!noUrl) {
      navigate(`?${exposedQueryString}`);
    }
  }, [exposedQueryString]);

  return {
    data: data?.items || [],
    columns: andtDTableCols,
    isLoading: isFetching,
    paginationProps,
    filterProps,
    hasFilters,
    maxHeight,
    queryString: exposedQueryString,
    filtersOnlyQueryString,
    showHeader,
    emptyRowText,
    stickyHeader,
    selectedRows,
    onSort,
    onSelectRows: (selectedRows: T[]) => {
      onSelectRows?.(selectedRows);
      setSelectedRows(selectedRows);
    },
    refetch: getItems,
    clearSelection,
    onRowClick,
    onRowMouseEnter,
    onRowMouseLeave,
    changeFiltersAndColumns,
  };
}
