import { useEffect, useMemo } from 'react';

import { DataTable } from '@app/components/tables/AntdTableWrapper';
import { TimeLine } from '@app/components/tables/AntdTableWrapper/components/Timeline';
import { useDataTable } from '@app/components/tables/AntdTableWrapper/hooks/useDataTable';
import { TDataColumns } from '@app/components/tables/AntdTableWrapper/types';
import { TAccountRecommendationExtended } from '@app/types/accountRecommendationExtended';
import { TActionItems } from '@app/components/tables/AntdTableWrapper/hooks/useTableFilters';

interface IConfigurationChangesProps<T extends { id: number | string }> {
  tableColumns: TDataColumns<T>;
  actionItems: TActionItems<T>[];
  timelineRange?: {
    startDate: Date;
    endDate: Date;
  };
  hoveredDate?: string;
  setTimelineRange: (range: { startDate: Date; endDate: Date }) => void;
  setHoveredDate: (hoveredDate?: string) => void;
  setActiveDataTableProps: (dataTableProps: ReturnType<typeof useDataTable<T>>) => void;
  handleAdditionalDetailsClicked: (props: { changeLogDate: string; eventsText: string }) => void;
  onRowClickCallback?: (row: T) => void;
  setSelectedRows: (rows: TAccountRecommendationExtended[]) => void;
}

const ConfigurationChanges = <T extends { id: number | string }>({
  tableColumns,
  actionItems,
  timelineRange,
  hoveredDate,
  setTimelineRange,
  setHoveredDate,
  setActiveDataTableProps,
  handleAdditionalDetailsClicked,
  onRowClickCallback,
  setSelectedRows,
}: IConfigurationChangesProps<T>) => {
  const customQueryKey = useMemo(() => {
    return `accountRecommendationHistory-odata date ge ${timelineRange?.startDate.toISOString()} and date le ${timelineRange?.endDate.toISOString()} and changeField eq 'IsCompliant'`;
  }, [timelineRange]);

  const dataTableProps = useDataTable<T>({
    actionItems,
    model: 'accountRecommendationHistory',
    exportExcel: { type: 'None', model: '' },
    columns: tableColumns,
    searchableColumns: ['productName', 'findings'],
    defaultOrder: {
      column: 'date',
      order: 'desc',
    },
    limit: 50,
    showTimeline: true,
    customQueryKey: customQueryKey,
    constantFilter: `date ge ${timelineRange?.startDate.toISOString()} and date le ${timelineRange?.endDate.toISOString()} and changeField eq 'IsCompliant'`,
    stickyHeader: true,
    onTimelineRangeChanged(newRange) {
      if (!newRange) return;

      setTimelineRange(newRange);
    },
    onRowMouseEnter(data, index) {
      setHoveredDate((data as any).date);
    },
    onRowMouseLeave(data, index) {
      setHoveredDate(undefined);
    },
    onRowClick: (data, index) => {
      onRowClickCallback?.(data);
    },
    onSelectRows: (selectedRows) => {
      /**
       * the StoryRecommendationExtended odata returns a property "accountRecommendationId"
       * to identify the accountRecommendationId of a recommendation. The type "TAccountRecommendationExtended"
       * has an id property to serve the same purpose. So to make them consistent and so that other
       * components wont confuse the ids used to execute actions, the property
       * "accountRecommendationId" is mapped to "id"
       */

      const mappedRows: TAccountRecommendationExtended[] = selectedRows.map((row: any) => ({
        ...row,
        id: row.accountRecommendationId,
      }));

      setSelectedRows(mappedRows);
    },
  });

  useEffect(() => {
    setActiveDataTableProps(dataTableProps);
  }, [dataTableProps]);

  return (
    <DataTable {...dataTableProps}>
      {({ filterProps }) => {
        if (!filterProps?.timelineRange) {
          return <></>;
        }

        return (
          <TimeLine
            type="configuration changes"
            additionalQueryParams="(becameCompliant ne '0' or becameNonCompliant ne '0')"
            handleAdditionalDetailsClicked={handleAdditionalDetailsClicked}
            startDate={filterProps?.timelineRange?.startDate}
            endDate={filterProps?.timelineRange?.endDate}
            hoveredDate={hoveredDate}
            setHoveredDate={setHoveredDate}
          />
        );
      }}
    </DataTable>
  );
};

export { ConfigurationChanges };
