import React from 'react';
import * as S from './BaseAccountNotificationsStyle';
import { BaseSpace } from '../BaseSpace/BaseSpace';
import { format, formatDistanceToNow } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { BaseRow } from '../BaseRow/BaseRow';

interface BaseNotificationProps {
  notificationId: number;
  title: string;
  timeSent: Date;
  isRead?: boolean;
  isHidden: boolean;
  onNotificationClick: (notificationId: number, actionType: 'notification' | 'hide' | 'unhide') => void;
}

export const BaseNotification: React.FC<BaseNotificationProps> = ({
  title,
  notificationId,
  timeSent,
  isRead,
  isHidden,
  onNotificationClick,
}) => {
  const navigate = useNavigate();
  const parsedDate = new Date(timeSent);
  const formattedDate = format(parsedDate, 'dd/MM/yyyy');
  const relativeTime = formatDistanceToNow(parsedDate, { addSuffix: true });

  const handleClick = () => {
    onNotificationClick(notificationId, 'notification');
    navigate(`account-notifications/${notificationId}`);
  };

  const handleHideClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onNotificationClick(notificationId, isHidden ? 'unhide' : 'hide');
  };

  return (
    <S.SpaceWrapper align="start" size="middle" onClick={handleClick} isRead={isRead}>
      <S.informationDiv>
        {!isRead && <S.UnreadCircle />}
        <BaseSpace direction="vertical">
          <BaseRow>
            <S.Title>{title}</S.Title>
          </BaseRow>
          <S.Date>{`${formattedDate} ● ${relativeTime}`}</S.Date>
        </BaseSpace>
      </S.informationDiv>
      <S.StyledBaseSpace>
        <S.Hide onClick={handleHideClick}>{isHidden ? 'Unarchive' : 'Archive'}</S.Hide>
      </S.StyledBaseSpace>
    </S.SpaceWrapper>
  );
};
