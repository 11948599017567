import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseNotification } from '@app/components/common/BaseAccountNotification/BaseAccountNotification';
import * as S from './AccountNotificationsStyles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { TAccountNotifications } from '@app/types/accountNotifications';
import { NotificationFilters } from './NotificationFilters/NotificationFilters';
import { Hide } from '@app/components/common/BaseAccountNotification/BaseAccountNotificationsStyle';

interface AccountNotificationsOverlayProps {
  data: TAccountNotifications[];
  onNotificationClick: (notificationId: number, actionType: 'notification' | 'hide' | 'unhide') => void;
  unreadCount: number;
}

export const AccountNotificationsOverlay: React.FC<AccountNotificationsOverlayProps> = ({
  data,
  onNotificationClick,
  unreadCount,
  ...props
}) => {
  const { t } = useTranslation();
  const [visibleCount, setVisibleCount] = useState(4);
  const [filter, setFilter] = useState<'all' | 'unread' | 'hidden'>('unread');

  const filteredNotifications = useMemo(() => {
    return data.filter((notification) => {
      if (filter === 'unread') {
        return !notification.isRead && !notification.isHidden;
      }
      if (filter === 'hidden') {
        return notification.isHidden;
      }
      return !notification.isHidden;
    });
  }, [filter, data]);

  const noticesList = useMemo(
    () =>
      filteredNotifications
        .slice(0, visibleCount)
        .map((notification, index) => (
          <BaseNotification
            key={index}
            notificationId={notification.notificationId}
            title={notification.title}
            timeSent={notification.timeSent}
            isRead={notification.isRead}
            isHidden={notification.isHidden}
            onNotificationClick={(notificationId, actionType) => onNotificationClick(notificationId, actionType)}
          />
        )),
    [filteredNotifications, visibleCount, onNotificationClick],
  );

  const handleShowMore = () => {
    setVisibleCount((prevCount) => Math.min(prevCount + 3, filteredNotifications.length));
  };

  const handleFilterChange = (newFilter: 'all' | 'unread' | 'hidden') => {
    setFilter(newFilter);
    setVisibleCount(4);
  };

  return (
    <S.NoticesOverlayMenu {...props}>
      <S.HeadingRow gutter={[20, 20]}>
        <BaseRow justify={'space-between'} align={'bottom'} style={{ width: '100%' }}>
          <S.Heading> Notifications</S.Heading>
          {/* <Hide>Mark All as Read</Hide> */}
        </BaseRow>
        <BaseRow>
          <NotificationFilters filter={filter} unreadCount={unreadCount} onFilterChange={handleFilterChange} />
        </BaseRow>
      </S.HeadingRow>
      <S.Divider />

      {filteredNotifications.length > 0 ? (
        <S.SpaceWrapper direction="vertical">
          {noticesList}
          {visibleCount < filteredNotifications.length && (
            <S.Btn type="link" onClick={handleShowMore}>
              Show More
            </S.Btn>
          )}
        </S.SpaceWrapper>
      ) : (
        <S.Text>{t('header.notifications.noNotifications')}</S.Text>
      )}
    </S.NoticesOverlayMenu>
  );
};
