import { useEffect, useMemo, useState } from 'react';
import { Checkbox, DatePicker, Divider, Dropdown, MenuProps, Radio, RadioChangeEvent, Space } from 'antd';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { ArrowLeftOutlined } from '@ant-design/icons';

import { TFilterValue } from '../types';
import { ChevronDownIcon } from '@app/components/icons';
import { RootState } from '@app/store/store';
import moment from 'moment';

const CustomDropdownContainer = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  min-width: 250px;
  max-width: 400px;
  max-height: 40vh;
  overflow: auto;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const TextFilterContainer = styled.div`
  padding: 10px 8px;
  gap: 12px;
  border: none;
  border-bottom: 1px solid #acacac;
  margin-bottom: 10px;
`;

const TextFilterInput = styled.input`
  background-color: transparent;
  border: none;
  font-size: 14px;
  font-weight: 400;
  outline: none;
`;

const ApplyButton = styled.button`
  background-color: #3a50d1;
  color: white;
  padding: 4px 24px;
  border: none;
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
  margin-left: auto;
  cursor: pointer;
`;

const OperatorsDropdownTrigger = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid #e9eaeb;
  gap: 16px;
  border: 1px solid #acacac;
  border-radius: 10px;
  padding: 10px;
  background-color: #fff;
  cursor: pointer;
`;

const OperatorsText = styled.span`
  color: #6d6d6d;
  font-weight: 600;
`;

const NumberInputContainer = styled.div`
  padding: 10px 12px;
  border: 1px solid #acacac;
  border-radius: 10px;
`;

const NumberInput = styled.input`
  background-color: transparent;
  border: none;
  font-size: 14px;
  font-weight: 400;
  outline: none;
`;

const DatePickerInput = styled(DatePicker)`
  margin: 12px 0px;
  width: 100%;
`;

const RangePickerInput = styled(DatePicker.RangePicker)`
  margin: 12px 0px;
`;

const OPERATORS = [
  {
    title: '==',
    value: 'eq',
  },
  {
    title: '!=',
    value: 'ne',
  },
  {
    title: '>',
    value: 'gt',
  },
  {
    title: '<',
    value: 'lt',
  },
  {
    title: '>=',
    value: 'ge',
  },
  {
    title: '<=',
    value: 'le',
  },
];

const DEFAULT_SELECTED_OPERATOR = 'eq';
const DROPDOWN_MAX_HEIGHT = '250px';

type TCustomFilterDropdownTypes = {
  type: 'text' | 'number' | 'enum' | 'enumArray' | 'date' | 'datetime' | 'daterange';
};

type TTextOptionsDropdownProps = {
  type: 'textOptions';
  options: { key: string; value: string }[];
  multipleSelect?: boolean;
};

type TBooleanDropdownProps = {
  type: 'boolean';
  hasNullOption?: boolean;
};

type TCustomDropdownProps = {
  title: string;
  dataIndex: string;
  enumKey?: string;
  defaultValues?: {
    type: 'text' | 'number' | 'boolean' | 'enum' | 'enumArray' | 'date' | 'datetime' | 'daterange' | 'textOptions';
    value: any;
  };
  onBackClicked?: () => void;
  onApply?: (props: TFilterValue) => void;
} & (TCustomFilterDropdownTypes | TTextOptionsDropdownProps | TBooleanDropdownProps);

export function CustomFilterDropdown({
  title,
  dataIndex,
  type,
  enumKey,
  defaultValues,
  onBackClicked,
  onApply,
  ...props
}: TCustomDropdownProps) {
  const appValues = useSelector<RootState>((state) => state.app.appValues);

  const [filterValue, setFilterValue] = useState('');
  const [selectedValues, setSelectedValues] = useState<any[]>([]);
  const [selectedOperator, setSelectedOperator] = useState(DEFAULT_SELECTED_OPERATOR);
  const [selectedBoolean, setSelectedBoolean] = useState<boolean | null>(null);
  const [selectedFilterValue, setSelectedFilterValue] = useState('');

  const defaultDateRangeValue = useMemo(() => {
    if (defaultValues?.type === 'date' && !!filterValue) {
      const dateValues = JSON.parse(filterValue);
      return [moment(dateValues[0]), moment(dateValues[1])];
    }

    return undefined;
  }, [filterValue]);

  const handleOperatorChange: MenuProps['onClick'] = (info) => {
    const newOperator = OPERATORS.find((op) => op.value === info.key);
    setSelectedOperator(newOperator?.value || '');
  };

  const handleOnApply = () => {
    if (type === 'enum' || type === 'enumArray') {
      onApply?.({ title, column: dataIndex, value: JSON.stringify(selectedValues), type, enumKey: enumKey || '' });
      return;
    }

    if (type === 'number') {
      onApply?.({ title, column: dataIndex, value: `${selectedOperator} ${filterValue}`, type });
      setSelectedOperator(DEFAULT_SELECTED_OPERATOR);
      return;
    }

    if (type === 'boolean') {
      onApply?.({ title, column: dataIndex, value: JSON.stringify(selectedBoolean), type });
      return;
    }

    if (type === 'textOptions') {
      const textOptions = (props as TTextOptionsDropdownProps).options;
      const isMultipleSelect = (props as TTextOptionsDropdownProps).multipleSelect;
      if (isMultipleSelect) {
        onApply?.({
          title,
          column: dataIndex,
          value: JSON.stringify(selectedValues),
          type,
          options: textOptions,
          multipleSelect: isMultipleSelect,
        });
        return;
      }

      onApply?.({
        title,
        column: dataIndex,
        value: selectedFilterValue,
        type,
        options: textOptions,
        multipleSelect: isMultipleSelect,
      });
      return;
    }

    onApply?.({ title, column: dataIndex, value: filterValue, type });
  };

  const handleOnSelect = (isChecked: boolean, value: any) => {
    if (isChecked) {
      setSelectedValues([...selectedValues, value]);
      return;
    }

    setSelectedValues(selectedValues.filter((sValue) => sValue !== value));
  };

  const handleRadioChange = (e: RadioChangeEvent) => {
    setSelectedBoolean(e.target.value);
  };

  const handleFilterOptionsRadioChange = (e: RadioChangeEvent) => {
    setSelectedFilterValue(e.target.value);
  };

  useEffect(() => {
    if (defaultValues) {
      if (defaultValues.type === 'enum') {
        setSelectedValues(JSON.parse(defaultValues.value));
      }

      if (
        defaultValues.type === 'enumArray' ||
        (defaultValues.type === 'textOptions' && !!(props as TTextOptionsDropdownProps).multipleSelect)
      ) {
        setSelectedValues(JSON.parse(defaultValues.value));
      }

      if (defaultValues.type === 'number') {
        // TODO
      }

      if (defaultValues.type === 'boolean') {
        if (defaultValues.value === 'true') {
          setSelectedBoolean(true);
        } else if (defaultValues.value == 'null') {
          setSelectedBoolean(null);
        } else {
          setSelectedBoolean(false);
        }
      }

      setFilterValue(defaultValues.value);
    }
  }, []);

  return (
    <CustomDropdownContainer>
      <TitleContainer>
        {!defaultValues ? <ArrowLeftOutlined onClick={onBackClicked} /> : null}
        <span>{title}</span>
      </TitleContainer>
      <Divider style={{ margin: '12px 0px' }} />

      {type === 'text' && (
        <TextFilterContainer>
          <TextFilterInput
            type="text"
            placeholder={`${title}...`}
            onChange={(e) => setFilterValue(e.target.value)}
            value={filterValue}
          />
        </TextFilterContainer>
      )}

      {type === 'textOptions' && (
        <>
          {!!(props as TTextOptionsDropdownProps).multipleSelect ? (
            <Space direction="vertical">
              {(props as TTextOptionsDropdownProps).options.map((opt) => (
                <Checkbox
                  key={opt.key}
                  checked={selectedValues.includes(opt.key.toLowerCase())}
                  onChange={(e) => handleOnSelect(e.target.checked, opt.key.toLowerCase())}
                >
                  {opt.value}
                </Checkbox>
              ))}
            </Space>
          ) : (
            <Radio.Group onChange={handleFilterOptionsRadioChange} value={selectedFilterValue}>
              <Space direction="vertical">
                {(props as TTextOptionsDropdownProps).options?.map((opt) => (
                  <Radio key={opt.key} value={opt.value}>
                    {opt.value}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          )}
        </>
      )}

      {type === 'number' && (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '12px', gap: '12px' }}>
          <Dropdown
            menu={{
              items: OPERATORS.map((op) => ({ label: op.title, key: op.value })),
              onClick: handleOperatorChange,
              selectable: true,
              defaultSelectedKeys: ['0'],
            }}
            trigger={['click']}
          >
            <OperatorsDropdownTrigger>
              <OperatorsText>{OPERATORS.find((op) => op.value === selectedOperator)?.title}</OperatorsText>
              <ChevronDownIcon fill="#3A50D1" />
            </OperatorsDropdownTrigger>
          </Dropdown>

          <NumberInputContainer>
            <NumberInput type="number" onChange={(e) => setFilterValue(e.target.value)} />
          </NumberInputContainer>
        </div>
      )}

      {type === 'enumArray' && enumKey && (
        <div style={{ marginBottom: '12px', overflow: 'auto', maxHeight: DROPDOWN_MAX_HEIGHT }}>
          {(appValues as any)[enumKey].map((val: any) => (
            <div
              key={val.value}
              style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '8px', gap: '10px' }}
            >
              <Checkbox
                checked={selectedValues.includes((val.value as number).toString())}
                onChange={(e) => handleOnSelect(e.target.checked, (val.value as number).toString())}
              />
              {val.label}
            </div>
          ))}
        </div>
      )}

      {type === 'enum' && enumKey && (
        <div style={{ marginBottom: '12px', overflow: 'auto', maxHeight: DROPDOWN_MAX_HEIGHT }}>
          {(appValues as any)[enumKey].map((val: any) => (
            <div
              key={val.value}
              style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '8px', gap: '10px' }}
            >
              <Checkbox
                checked={selectedValues.includes((val.value as number).toString())}
                onChange={(e) => handleOnSelect(e.target.checked, (val.value as number).toString())}
              />
              {val.label}
            </div>
          ))}
        </div>
      )}

      {type === 'boolean' && (
        <Radio.Group onChange={handleRadioChange} value={selectedBoolean}>
          <Space direction="vertical">
            {(props as TBooleanDropdownProps).hasNullOption && <Radio value={null}>N/A</Radio>}
            <Radio value={true}>True</Radio>
            <Radio value={false}>False</Radio>
          </Space>
        </Radio.Group>
      )}

      {type === 'date' && (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <RangePickerInput
          format="DD/MM/yyyy"
          onChange={(val) => setFilterValue(JSON.stringify(val))}
          {...(!!defaultDateRangeValue && { value: defaultDateRangeValue })}
        />
      )}
      {type === 'datetime' && (
        <RangePickerInput showTime format="DD/MM/yyyy HH:mm" onChange={(val) => setFilterValue(JSON.stringify(val))} />
      )}
      {/* {type === 'daterange' && <RangePickerInput format="DD/MM/yyyy" />} */}

      <div
        style={{
          display: 'flex',
        }}
      >
        <ApplyButton onClick={handleOnApply}>Apply</ApplyButton>
      </div>
    </CustomDropdownContainer>
  );
}
