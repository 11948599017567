import styled from 'styled-components';
import { BaseAvatar } from '../BaseAvatar/BaseAvatar';
import { BaseSpace } from '../BaseSpace/BaseSpace';
import { BaseTypography } from '../BaseTypography/BaseTypography';

export const NotificationIcon = styled(BaseAvatar)``;

export const Title = styled(BaseTypography.Text)`
  font-size: 0.875rem;
  font-weight: 600;
`;

export const Description = styled(BaseTypography.Text)`
  font-size: 0.875rem;
`;

export const Date = styled(BaseTypography.Text)`
  font-size: 0.675rem;
  font-weight: 600;
  color: grey;
`;

export const StyledBaseSpace = styled.div`
  height: 50px;
  align-content: center;
`;

export const Hide = styled(BaseTypography.Text)`
  font-size: 0.775rem;
  font-weight: 400;
  color: #6455d6;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

interface SpaceWrapperProps {
  isRead?: boolean;
}

export const informationDiv = styled.div``;

export const SpaceWrapper = styled(BaseSpace)<SpaceWrapperProps>`
  background-color: ${({ isRead }) => (isRead ? 'white' : '#ECEFFD')};
  padding: 12px;
  cursor: pointer;
  width: 100%;
  justify-content: space-between;
  border-radius: 4px;
  height: max-content;

  &:hover {
    background-color: ${({ isRead }) => (isRead ? '#F6F6F6' : '#ECEFFD')};
  }
`;

export const UnreadCircle = styled.div`
  width: 10px;
  height: 10px;
  background-color: #6455d6;
  border-radius: 50%;
  margin-right: 8px;
  display: inline-block;
`;
