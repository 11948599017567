import { BlobServiceClient } from '@azure/storage-blob';

const account = process.env.REACT_APP_STORAGE_ACCOUNT;
const sasToken = process.env.REACT_APP_STORAGE_SAS_TOKEN;
const defaultContainerName = process.env.REACT_APP_STORAGE_CONTAINER || '';
const environmentName = process.env.REACT_APP_ENV || 'local';

const blobServiceClient = new BlobServiceClient(`https://${account}.blob.core.windows.net/?${sasToken}`);

export const uploadFileToStorage = async (
  filename: string,
  filetype: string,
  filedata: Uint8Array,
  containerName?: string,
) => {
  const resolvedContainerName = containerName ? `${environmentName}-${containerName}` : defaultContainerName;

  if (!resolvedContainerName) {
    console.error('Container name is not specified.');
    return;
  }

  const containerClient = blobServiceClient.getContainerClient(resolvedContainerName);
  const blobClient = containerClient.getBlockBlobClient(filename);

  try {
    await blobClient.uploadData(filedata, { blobHTTPHeaders: { blobContentType: filetype } });
    return blobClient.url;
  } catch (err) {
    console.error('Error uploading file to storage:', err);
    return undefined;
  }
};

export const getBlobFileUrl = (filename: string, containerName?: string) => {
  const resolvedContainerName = containerName ? `${environmentName}-${containerName}` : defaultContainerName;

  if (!resolvedContainerName) {
    console.error('Container name is not specified.');
    return undefined;
  }

  try {
    const containerClient = blobServiceClient.getContainerClient(resolvedContainerName);
    const blobClient = containerClient.getBlockBlobClient(filename);
    return blobClient.url;
  } catch (error) {
    console.error('Error retrieving blob file URL:', error);
    return undefined;
  }
};
