import styled from 'styled-components';

export const FiltersContainer = styled.div`
  display: flex;
  gap: 24px;
`;

export const FilterWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const LinkText = styled.span<{ active: boolean }>`
  font-weight: ${({ active }) => (active ? '500' : '400')};
  color: ${({ active }) => (active ? '#6455D6' : '#888888')};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 2px;
  position: relative;
  padding-bottom: 8px;
  font-size: 14px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    height: 2px;
    background-color: ${({ active }) => (active ? 'var(--primary-color)' : 'transparent')};
    transition: background-color 0.3s ease;
  }
`;

export const NotificationBadge = styled.span<{ active: boolean }>`
  background-color: ${({ active }) => (active ? '#e7e9ff' : '#E7E7E7')};
  color: ${({ active }) => (active ? '#5661e5' : '#454545')};
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  width: 16px;
  height: 16px;
  line-height: 1;
  margin-left: 4px;
`;
