import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import ManageGroupsHeader from '@app/components/apps/manage-groups/ManageGroupsHeader';
import { TDataColumns } from '@app/components/tables/AntdTableWrapper/types';
import { TGroupExtended } from '@app/types/groupExtended';
import { useDataTable } from '@app/components/tables/AntdTableWrapper/hooks/useDataTable';
import { TActionItems } from '@app/components/tables/AntdTableWrapper/hooks/useTableFilters';
import { DataTable } from '@app/components/tables/AntdTableWrapper';
import { format, set } from 'date-fns';
import { getEnumValue } from '@app/services/enum.service';
import { useState } from 'react';
import GroupForm from '@app/components/apps/manage-groups/GroupForm';
import useGroups from '@app/components/apps/manage-groups/useGroups';
import ConfirmationModal from '@app/components/apps/manage-groups/ConfirmationModal';
import { useDeleteGroup } from '@app/api/groups.api';
import DeleteConfirmationModal from '@app/components/apps/manage-groups/DeleteConfirmationModal';
import { TGroupPermission } from '@app/types/groupPermission';
import { useGetGroupPermissions, useGetGroups, useCreateGroup, useUpdateGroup } from '@app/api/groups.api';

const manageGroupsTableCols: TDataColumns<TGroupExtended> = [
  {
    title: 'Group Name',
    dataIndex: 'name',
    type: 'text',
    showSortDirections: true,
    allowFiltering: true,
  },
  {
    title: 'Creation Date',
    dataIndex: 'creationTime',
    type: 'date',
    render: (text) => format(new Date(text), 'MM/dd/yyyy'),
  },
  {
    title: 'Last Update Time',
    dataIndex: 'lastUpdatedTime',
    type: 'date',
    render: (text) => format(new Date(text), 'MM/dd/yyyy hh:mm aa'),
  },
  {
    title: 'No. Of Members',
    dataIndex: 'numberOfUsers',
    type: 'number',
  },
];

const ManageGroupsPage = () => {
  const [selectedGroup, setSelectedGroup] = useState<TGroupExtended | undefined>(undefined);
  const [error, setError] = useState<string | null>(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const { mutateAsync: deleteGroup, isLoading: isDeleting } = useDeleteGroup();
  const { mutateAsync: createGroup, isLoading: isCreating } = useCreateGroup();
  const { mutateAsync: updateGroup, isLoading: isUpdating } = useUpdateGroup();
  const { data: groupPermissions } = useGetGroupPermissions();
  const { data: groups } = useGetGroups();
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);

  const { toggle, ...groupFormProps } = useGroups({
    onOk: ({
      id,
      name,
      description,
      creationTime,
      lastUpdatedTime,
      numberOfUsers,
      status,
      accountId,
      permissions,
      recommendationScopingEnabled,
      recommendationsFilter,
    }) => {
      setSelectedGroup({
        id: id || '',
        name,
        description,
        creationTime,
        lastUpdatedTime,
        numberOfUsers,
        status,
        accountId,
        permissions,
        recommendationScopingEnabled,
        recommendationsFilter,
      });
      setIsConfirmationModalOpen(true);
      setIsEdit(isEdit);
    },
    onCancel: () => {
      setSelectedGroup(undefined);
      setIsEdit(false);
    },
    group: selectedGroup,
  });

  const actionItems: TActionItems<TGroupExtended>[] = [
    {
      label: 'Edit',
      key: 'edit',
      multiSelect: false,
      onClick: ({
        id,
        name,
        description,
        creationTime,
        lastUpdatedTime,
        numberOfUsers,
        status,
        accountId,
        recommendationScopingEnabled,
        recommendationsFilter,
      }) => {
        setSelectedGroup({
          id: id || '',
          name,
          description,
          creationTime,
          lastUpdatedTime,
          numberOfUsers,
          status,
          accountId,
          permissions: Array.isArray(groups)
            ? groups
                .find((g: TGroupExtended) => g.id === id)
                ?.permissions.map((permission: TGroupPermission) => permission.id)
            : [],
          recommendationScopingEnabled,
          recommendationsFilter,
        });
        setIsEdit(true);
        toggle();
      },
    },
    {
      label: 'Delete',
      key: 'delete',
      multiSelect: false,
      onClick: (group) => {
        setSelectedGroup(group);
        setIsDeleteConfirmationModalOpen(true);
      },
      show: (group) => Number(group.numberOfUsers) === 0,
    },
  ];

  const dataTableProps = useDataTable<TGroupExtended>({
    model: 'groupExtended',
    columns: manageGroupsTableCols,
    actionItems,
    constantFilter: `status eq ${getEnumValue('GroupStatus', 'Active')}`,
    onRowClick: ({
      id,
      name,
      description,
      creationTime,
      lastUpdatedTime,
      numberOfUsers,
      status,
      accountId,
      recommendationScopingEnabled,
      recommendationsFilter,
    }) => {
      setSelectedGroup({
        id,
        name,
        description,
        creationTime,
        lastUpdatedTime,
        numberOfUsers,
        status,
        accountId,
        permissions: Array.isArray(groups)
          ? groups
              ?.find((g: TGroupExtended) => g.id === id)
              ?.permissions.map((permission: TGroupPermission) => permission.id)
          : [],
        recommendationScopingEnabled,
        recommendationsFilter,
      });
      setIsEdit(true);
      toggle();
    },
  });

  const resetState = () => {
    setSelectedGroup(undefined);
    setIsConfirmationModalOpen(false);
    toggle();
    dataTableProps.clearSelection();
    dataTableProps.refetch();
  };

  const handleCreateOrUpdateGroup = async () => {
    if (selectedGroup) {
      const payload = {
        name: selectedGroup.name,
        description: selectedGroup.description,
        permissions: selectedGroup.permissions || [],
        recommendationScopingEnabled: selectedGroup.recommendationScopingEnabled,
        recommendationsFilter: selectedGroup.recommendationsFilter,
      };

      if (isEdit) {
        await updateGroup(
          {
            id: Number(selectedGroup.id),
            payload,
          },
          {
            onSuccess: () => resetState(),
          },
        );
      } else {
        await createGroup(payload, {
          onSuccess: () => resetState(),
        });
      }
    }
  };

  const handleConfirmationModalClose = () => {
    setIsConfirmationModalOpen(false);
  };

  const handleDeleteGroup = async () => {
    if (selectedGroup) {
      await deleteGroup(Number(selectedGroup.id));
      dataTableProps.refetch();
      setIsDeleteConfirmationModalOpen(false);
      dataTableProps.clearSelection();
    }
  };

  const handleDeleteConfirmationModalClose = () => {
    setSelectedGroup(undefined);
    setIsDeleteConfirmationModalOpen(false);
  };

  return (
    <>
      <PageTitle>Groups Management</PageTitle>
      <ManageGroupsHeader onCreateGroup={toggle} />
      <DataTable {...dataTableProps} />
      <GroupForm
        {...groupFormProps}
        group={selectedGroup}
        groupPermissions={groupPermissions}
        isEdit={isEdit}
        error={error}
      />
      <ConfirmationModal
        open={isConfirmationModalOpen}
        message={`Are you sure you want to ${isEdit ? 'update' : 'create'} this group?`}
        onOk={handleCreateOrUpdateGroup}
        onCancel={handleConfirmationModalClose}
        isLoading={isCreating || isUpdating}
      />
      <DeleteConfirmationModal
        open={isDeleteConfirmationModalOpen}
        message={`Are you sure you want to delete this group?`}
        onOk={handleDeleteGroup}
        onCancel={handleDeleteConfirmationModalClose}
        isLoading={isDeleting}
      />
    </>
  );
};

export default ManageGroupsPage;
