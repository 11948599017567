import { useState } from 'react';
import { Modal, ModalFuncProps, Radio } from 'antd';
import styled from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';
import { TExportExcel } from '@app/types/exportTypes';

const ApplyButton = styled.button`
  background-color: #3a50d1;
  color: white;
  padding: 8px 60px;
  border: 1px solid #3a50d1;
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
`;

const CancelButton = styled.button`
  background-color: #fff;
  color: #272727;
  padding: 8px 60px;
  border: 1px solid #272727;
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 20px 0px;
`;

const FormTitle = styled.div`
  font-weight: 800;
  font-size: 20px;
  line-height: 23px;
  color: #272727;
`;

const ExportDescription = styled.div`
  margin-top: 12px;
`;

interface IModalFooterProps {
  onCancel: () => void;
  onApply: () => void;
}

const ModalFooter = ({ onCancel, onApply }: IModalFooterProps) => {
  return (
    <FooterContainer>
      <CancelButton onClick={onCancel} color="cancel">
        Cancel
      </CancelButton>
      <ApplyButton onClick={onApply} color="apply">
        Apply
      </ApplyButton>
    </FooterContainer>
  );
};

interface IExportOptionsModalProps extends ModalFuncProps {
  exportExcel: TExportExcel;
  onCancel: () => void;
  onApply: (exportType: TExportExcel) => void;
}

export function ExportOptionsModal({ exportExcel, onCancel, onApply, ...modalProps }: IExportOptionsModalProps) {
  const [selectedExportType, setSelectedExportType] = useState<TExportExcel>({
    type: 'Standard',
    model: exportExcel.model,
  });

  const handleOnApply = () => {
    onApply(selectedExportType);
    onCancel();
  };

  return (
    <Modal
      {...modalProps}
      destroyOnClose={true}
      closeIcon={<CloseOutlined style={{ color: '#D81C2E' }} onClick={onCancel} />}
      footer={<ModalFooter onCancel={onCancel} onApply={handleOnApply} />}
    >
      <HeaderContainer>
        <FormTitle>Select an Export Type</FormTitle>
        <ExportDescription>
          <p>
            <strong>Standard Export: </strong>
            <span>For general viewing purposes.</span>
          </p>
          <p>
            <strong>Model Export: </strong>
            <span>When you need to import data back into the system.</span>
          </p>
        </ExportDescription>
      </HeaderContainer>
      <Radio.Group
        onChange={(e) =>
          setSelectedExportType({
            ...selectedExportType,
            type: e.target.value as TExportExcel['type'],
          })
        }
        value={selectedExportType.type}
      >
        <Radio value="Standard">Standard</Radio>
        <Radio value="Model">Model</Radio>
      </Radio.Group>
    </Modal>
  );
}
